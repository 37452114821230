import personnummer from 'personnummer'
import isFuture from 'date-fns/isFuture'

const SWEDISH_GD_NUMBER_LENGTH = 11
const SWEDISH_CORPORATE_IDENTITY_NUMBER_LENGTH = 10

const isSwedishGDNumber = (value) => {
  return value && value.length === SWEDISH_GD_NUMBER_LENGTH && value.slice(0, 3) === '302'
}

export const isCorporateIdentityNumber = (value) => {
  return !value || value.length === SWEDISH_CORPORATE_IDENTITY_NUMBER_LENGTH && value[2] > 2
}

export const phoneNumberValidator = val => /^(\+?\d{3,})$/.test(val)

export const requiredIfOtherBillingAddress = (val, vm) => {
  return !vm.showBillingAddress || !!val
}

export const requiredIfPrivate = (val, vm) => {
  return vm.isCorporate || !!val
}

export const requiredIfCorporate = (val, vm) => {
  return !vm.isCorporate || !!val
}

export const swedishPersonalNumber = (value) => {
  return !value || isSwedishGDNumber(value)
      || personnummer.valid(value)
}

export const dateIsNotPast = (val) => {
  const selected = new Date(val)

  return isFuture(new Date(selected.getFullYear(), selected.getMonth(), selected.getDate() + 1), new Date())
}
