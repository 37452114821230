<template>
  <div class="offer-summary">
    <img
      alt="provider"
      :src="offerLogo"
      width="96">

    <div>
      <h2 class="offer-summary__heading">
        {{ offer.friendlyName }}
      </h2>

      <p v-html="offer.body" />

      <p>
        Pris: <span class="offer-summary__price">{{ offer.price }}</span> kr/månad
      </p>

      <p v-if="isOfferFromTelia">
        Behandling av order påbörjas inom 24 h vardagar.
        <br>
        För snabbare support kontakta oss på 90 200
      </p>
    </div>

    <template v-if="isOfferFromTelia">
      <MainButton
        class="offer-summary__button"
        :type="'secondary'"
        :label="'Beställ detta erbjudande'"
        @click="$emit('show-form')" />

      <a
        id="offer-summary__back-button"
        @click="$emit('back')">
        Tillbaks till listan med alla tjänster du kan beställa
      </a>
    </template>
  </div>
</template>

<script>
import MainButton from '@/components/MainButton'

export default {
  components: {
    MainButton
  },
  props: {
    offer: {
      type: Object,
      required: true
    },
    isOfferFromTelia: {
      type: Boolean,
      required: true
    },
    serviceProviderContent: {
      type: Object,
      required: true
    }
  },
  computed: {
    offerLogo () {
      return this.serviceProviderContent.fields.logo.fields.file.url
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.offer-summary {
  padding: 32px;
  margin-right: 12px;
  width: 50%;
  background: $grey-100;

  &__heading {
    margin: 32px 0;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 1.5px;
  }

  &__price {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 1.5px;
  }

  &__button {
    margin: 40px 0;
  }

  @media(max-width: $breakpoint-sm) {
    padding: 26px;
    margin-right: 0;
    width: 100%;
    transition: all 0.5s ease-in-out;
  }
}
</style>
