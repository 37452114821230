<template>
  <div class="offer-form">
    <span class="offer-form__heading">
      {{ offer.friendlyName }}
    </span>

    <div
      v-if="address"
      class="offer-form__field-wrapper">
      <label>Installationsadress</label>
      <div class="offer-form__address-wrapper">
        <span>{{ address.streetName }} {{ address.streetNumber }},</span>
        <span>{{ address.zipCode }} {{ address.city }}</span>
      </div>
    </div>

    <div class="offer-form__field-wrapper">
      <TextInput
        id="connection-date"
        ref="connectionDate"
        v-model="form.connectionDate"
        :validator="$v.form.connectionDate"
        :validation-messages="validationMessages.connectionDate"
        label="Inkopplingsdatum"
        :min="today"
        type="date" />
    </div>

    <template v-if="offer.corporate">
      <div class="offer-form__field-wrapper">
        <TextInput
          id="organisation-number"
          ref="organisationNumber"
          v-model="form.organisationNumber"
          :validator="$v.form.organisationNumber"
          :validation-messages="validationMessages.organisationNumber"
          label="Organisationsnummer" />
      </div>

      <div class="offer-form__field-wrapper">
        <TextInput
          id="company-name"
          ref="companyName"
          v-model="form.companyName"
          :validator="$v.form.companyName"
          :validation-messages="validationMessages.companyName"
          label="Företagsnamn" />
      </div>

      <div class="offer-form__field-wrapper">
        <TextInput
          id="company-contact-person"
          ref="companyContactPerson"
          v-model="form.companyContactPerson"
          :validator="$v.form.companyContactPerson"
          :validation-messages="validationMessages.companyContactPerson"
          label="Förnamn och efternamn" />
      </div>
    </template>

    <template v-else>
      <div class="offer-form__field-wrapper">
        <TextInput
          id="personal-number"
          ref="personalNumber"
          v-model="form.personalNumber"
          :validator="$v.form.personalNumber"
          :validation-messages="validationMessages.personalNumber"
          label="Personnummer" />
      </div>

      <div class="offer-form__field-wrapper">
        <TextInput
          id="first-name"
          ref="firstName"
          v-model="form.firstName"
          :validator="$v.form.firstName"
          :validation-messages="validationMessages.firstName"
          label="Förnamn" />
      </div>

      <div class="offer-form__field-wrapper">
        <TextInput
          id="last-name"
          ref="lastName"
          v-model="form.lastName"
          :validator="$v.form.lastName"
          :validation-messages="validationMessages.lastName"
          label="Efternamn" />
      </div>
    </template>

    <div class="offer-form__field-wrapper">
      <TextInput
        id="phone-number"
        ref="phoneNumber"
        v-model="form.phoneNumber"
        :validator="$v.form.phoneNumber"
        :validation-messages="validationMessages.phoneNumber"
        label="Mobilnummer"
        type="tel" />
    </div>

    <div class="offer-form__field-wrapper">
      <TextInput
        id="email"
        ref="email"
        v-model="form.email"
        :validator="$v.form.email"
        :validation-messages="validationMessages.email"
        type="email"
        label="E-post" />
    </div>

    <div class="offer-form__field-wrapper">
      <label>Fakturor skickas till</label>

      <RadioInput
        id="billing-address-toggle-false"
        v-model="form.showBillingAddress"
        class="offer-form__radio-wrapper"
        label="Installationsadress"
        :radio-value="false" />

      <RadioInput
        id="billing-address-toggle-true"
        v-model="form.showBillingAddress"
        class="offer-form__field-wrapper"
        label="Annan adress"
        :radio-value="true" />
    </div>

    <template v-if="form.showBillingAddress">
      <div class="offer-form__billing-address">
        <div class="offer-form__field-wrapper">
          <TextInput
            id="invoice-street-address"
            ref="invoiceStreetAddress"
            v-model="form.invoiceStreetAddress"
            :validator="$v.form.invoiceStreetAddress"
            :validation-messages="validationMessages.invoiceStreetAddress"
            label="Adress" />
        </div>

        <div class="offer-form__field-wrapper">
          <TextInput
            id="invoice-postal-code"
            ref="invoicePostalCode"
            v-model="form.invoicePostalCode"
            :validator="$v.form.invoicePostalCode"
            :validation-messages="validationMessages.invoicePostalCode"
            label="Postnummer" />
        </div>

        <div class="offer-form__field-wrapper">
          <TextInput
            id="invoice-city"
            ref="invoiceCity"
            v-model="form.invoiceCity"
            :validator="$v.form.invoiceCity"
            :validation-messages="validationMessages.invoiceCity"
            label="Stad" />
        </div>
      </div>
    </template>

    <div class="offer-form__terms-wrapper">
      <label>Villkor</label>

      <div
        v-for="(term, index) in terms"
        :key="index">
        <span class="offer-form__bullet">&#8226;</span>
        <a
          target="_blank"
          :href="term.fields.file.url">{{ term.fields.file.fileName }}</a>
      </div>
    </div>

    <div class="offer-form__checkbox-wrapper">
      <Checkbox
        id="terms-checkbox"
        v-model="termsAccepted"
        :checked-value="true"
        :label="'Jag har läst och godkänner villkoren'" />
    </div>

    <div
      v-if="isException"
      class="offer-form__modal-wrapper">
      <Modal
        position="left"
        :closable="false"
        variant="warning"
        class="mb-5">
        <template>
          <h3>Något gick fel</h3>
        </template>
      </Modal>
    </div>

    <div class="offer-form__submit-wrapper">
      <MainButton
        :loading="isPending"
        :disabled="disabled || !termsAccepted"
        :expanded="true"
        :label="'Beställ'"
        @click="$emit('submit', form)" />
    </div>
  </div>
</template>

<script>
import TextInput from '@/components/form-components/TextInput'
import RadioInput from '@/components/form-components/RadioInput'
import Checkbox from '@/components/form-components/Checkbox'
import MainButton from '@/components/MainButton'
import Modal from '@/components/modal/Modal'

import {
  phoneNumberValidator,
  requiredIfOtherBillingAddress,
  requiredIfPrivate,
  requiredIfCorporate,
  swedishPersonalNumber,
  dateIsNotPast,
  isCorporateIdentityNumber
} from '@/utils/validation.utils'

import {
  alphaNum,
  email,
  required,
  maxLength
} from 'vuelidate/lib/validators'
import format from 'date-fns/format'

export default {
  components: {
    TextInput,
    RadioInput,
    Checkbox,
    MainButton,
    Modal
  },
  props: {
    offer: {
      type: Object,
      required: true
    },
    address: {
      type: Object,
      required: true
    },
    isException: {
      type: Boolean,
      required: true
    },
    isPending: {
      type: Boolean,
      required: true
    },
    terms: {
      type: Array,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      termsAccepted: false,

      form: {
        isCorporate: this.offer.corporate,
        connectionDate: null,
        organisationNumber: null,
        companyName: null,
        companyContactPerson: null,
        personalNumber: null,
        firstName: null,
        lastName: null,
        phoneNumber: null,
        email: null,
        showBillingAddress: false,
        invoiceStreetAddress: null,
        invoicePostalCode: null,
        invoiceCity: null
      },

      validationMessages: {
        connectionDate: {
          required: 'Önskat anslutningsdatum',
          dateIsNotPast: 'Ange anslutningsdatum. Anslutningsdatum i det förflutna accepteras inte.'
        },
        organisationNumber: {
          requiredIfCorporate: 'Organisationsnummer krävs',
          isCorporateIdentityNumber: 'Ange organisationsnummer (XXXXXXXXXX)',
          alphaNum: 'Enbart siffror'
        },
        companyName: {
          requiredIfCorporate: 'Företagsnamn krävs'
        },
        companyContactPerson: {
          requiredIfCorporate: 'Förnamn och efternamn krävs'
        },
        personalNumber: {
          requiredIfPrivate: 'Personnummer krävs',
          alphaNum: 'Enbart siffror',
          swedishPersonalNumber: 'Ange personnummer (YYYYMMDDXXXX) eller GD-nummer (302XXXXXXX).'
        },
        firstName: {
          requiredIfPrivate: 'Förnamn krävs',
          maxLength: 'För länge'
        },
        lastName: {
          requiredIfPrivate: 'Efternamn krävs',
          maxLength: 'För länge'
        },
        phoneNumber: {
          required: 'Telefonnummer krävs',
          phoneNumberValidator: 'Måste vara en giltig telefonnummer'
        },
        email: {
          required: 'E-post krävs',
          email: 'Måste vara en giltig e-post'
        },
        invoiceStreetAddress: {
          requiredIfOtherBillingAddress: 'Gatuadress krävs'
        },
        invoicePostalCode: {
          requiredIfOtherBillingAddress: 'Postnummer krävs'
        },
        invoiceCity: {
          requiredIfOtherBillingAddress: 'Stad krävs'
        }
      }
    }
  },
  computed: {
    today () {
      return format(new Date(), 'yyyy-MM-dd')
    }
  },
  validations: {
    form: {
      connectionDate: {
        required,
        dateIsNotPast
      },
      organisationNumber: {
        requiredIfCorporate,
        isCorporateIdentityNumber,
        alphaNum
      },
      companyName: {
        requiredIfCorporate
      },
      companyContactPerson: {
        requiredIfCorporate
      },
      personalNumber: {
        requiredIfPrivate,
        swedishPersonalNumber,
        alphaNum
      },
      firstName: {
        requiredIfPrivate,
        maxLength: maxLength(150)
      },
      lastName: {
        requiredIfPrivate,
        maxLength: maxLength(150)
      },
      phoneNumber: {
        required,
        phoneNumberValidator
      },
      email: {
        required,
        email
      },
      invoiceStreetAddress: {
        requiredIfOtherBillingAddress
      },
      invoicePostalCode: {
        requiredIfOtherBillingAddress
      },
      invoiceCity: {
        requiredIfOtherBillingAddress
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.offer-form {
  margin-left: 12px;
  width: 50%;

  @media(max-width: $breakpoint-sm) {
    margin-top: 40px;
    margin-left: 0;
    width: 100%;
  }

  &__heading {
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 22px;
    line-height: 33px;
    letter-spacing: 1.5px;
  }

  &__address-wrapper {
    margin-top: 8px;
    display: flex;
    flex-direction: column;
  }

  &__field-wrapper {
    max-width: 384px;
    margin-top: 24px;
  }

  &__radio-wrapper {
    margin-top: 16px;
  }

  &__terms-wrapper {
    margin-top: 40px;

    & div {
      margin-top: 16px;
      margin-left: 8px;
    }
  }

  &__checkbox-wrapper, &__modal-wrapper {
    margin-top: 30px;
  }

  &__submit-wrapper {
    max-width: 330px;
    margin-top: 40px;
  }

  &__bullet {
    color: $core-teal-500;
    margin-right: 8px;
  }

  &__billing-address {
    padding-left: 32px;
    border-left: solid 1rem $grey-100;
  }
}
</style>
