<template>
  <section class="offer-page">
    <PageBanner
      v-if="provider"
      :heading="'Erbjudande från ' + provider.name" />
    <PageBody>
      <section>
        <ConnectedServicesModal :closable="false" />

        <a @click="onBack">
          <img
            class="back-icon"
            alt="arrow"
            src="@/assets/svg/arrow_back.svg"
            width="12"
            height="9">Alla tjänster du kan beställa
        </a>
        <div class="content-container">
          <div
            v-if="selectedOffer"
            class="offer">
            <OfferSummary
              :service-provider-content="serviceProviderContent"
              :offer="selectedOffer"
              :is-offer-from-telia="isOfferFromTelia"
              @show-form="onShowForm"
              @back="onBack" />

            <OfferForm
              v-if="!isOfferFromTelia || showForm"
              ref="offerForm"
              :offer="selectedOffer"
              :address="selectedAddress"
              :is-corporate="selectedOffer.corporate"
              :is-pending="isPending"
              :is-exception="exception"
              :disabled="isConnectedServices"
              :terms="serviceProviderContent.fields.terms"
              @submit="onSubmit" />
          </div>
        </div>
      </section>
    </PageBody>
  </section>
</template>

<script>
import {
  mapState, mapActions, mapGetters
} from 'vuex'
import PageBanner from '@/components/views/PageBanner'
import PageBody from '@/components/views/PageBody'

import OfferSummary from '@/components/offer/OfferSummary'
import OfferForm from '@/components/offer/OfferForm'
import ConnectedServicesModal from '@/components/ConnectedServicesModal'
import { RouteName } from '@/router/router-constants'
import { scrollToFirstInvalidField } from '@/utils/window.utils'

export default {
  components: {
    ConnectedServicesModal,
    PageBanner,
    PageBody,
    OfferSummary,
    OfferForm
  },
  data () {
    return {
      showForm: false
    }
  },
  computed: {
    ...mapGetters('BBWFlow', [
      'addressId',
      'companyId',
      'companyId',
      'punktId',
      'koId',
      'estateOwner',
      'checkSum'
    ]),
    ...mapState('AvailableOffers', ['selectedOffer']),
    ...mapGetters('AvailableOffers', ['connectedServices']),
    ...mapGetters('AddressSearch', ['selectedAddress']),
    ...mapState('ServiceProviders', ['content']),
    ...mapState('OrderSubmit', [
      'exception',
      'isPending'
    ]),
    provider () {
      return this.selectedOffer.serviceProvider
    },
    isOfferFromTelia () {
      return this.provider.name.toLowerCase() === 'telia'
    },
    serviceProviderContent () {
      return this.content.items.find(serviceProvider => serviceProvider.fields.id === this.selectedOffer.serviceProvider.id)
    },
    isConnectedServices () {
      return !!this.connectedServices.length
    }
  },
  methods: {
    ...mapActions('OrderSubmit', ['submitOrder']),
    onBack () {
      this.$router.back()
    },
    onShowForm () {
      this.showForm = true
    },
    async onSubmit (form) {
      const formComponent = this.$refs.offerForm
      const formValidator = formComponent.$v.form
      formValidator.$touch()

      if (formValidator.$invalid) {
        scrollToFirstInvalidField(formComponent, formValidator)

      } else {
        const payload = {
          addressId: this.addressId,
          companyId: this.companyId,
          punktId: this.punktId,
          koNumber: this.koId,
          estateOwner: this.estateOwner,
          checkSum: this.checkSum,
          isCorporate: this.selectedOffer.corporate,

          offerId: this.selectedOffer.id,

          connectionDate: form.connectionDate,
          personalNumber: form.personalNumber || form.organisationNumber,
          firstName: form.firstName || form.companyContactPerson,
          lastName: form.lastName || form.companyName,
          mobilePhone: form.phoneNumber,
          email: form.email,

          invoiceStreetAddress: form.invoiceStreetAddress,
          invoicePostalCode: form.invoicePostalCode,
          invoiceCity: form.invoiceCity
        }
        await this.submitOrder(payload)

        if (!this.exception) {
          this.$router.push({ name: RouteName.closed.ThankYou })
        }
      }

    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";

.back-icon {
  margin-right: 8px;
}

.content-container {
  padding-top: 56px;
  padding-bottom: 80px;

  @media(max-width: $breakpoint-sm) {
    padding-top: 32px;
    transition: all 0.5s ease-in-out;
  }
}

.offer {
  display: flex;
  align-items: flex-start;
  align-content: flex-start;

  @media(max-width: $breakpoint-sm) {
    flex-direction: column;
    transition: all 0.5s ease-in-out;

    &-loading-modal {
      margin: 0 -28px;
    }
  }
}
</style>
